<template>
  <b-container class="p-0 m-0 g-0" fluid id="app">
    <b-navbar toggleable="sm" variant="danger" type="dark" class="p-1 m-0 g-0" fixed="top">
      <b-navbar-brand v-on:click="closeAllBut('showIntro')" class="p-1 m-1 g-0">
        Andrew Stephen
      </b-navbar-brand>
      <b-button-group size="sm" class="p-1 m-1 g-0">
        <b-button size="sm" class="my-2 my-sm-0" v-on:click="lang = 'en'" :variant="showLangEnVar" :pressed ="showLangEnType"><i class="fab fa-canadian-maple-leaf"></i> EN</b-button>
        <b-button size="sm" class="my-2 my-sm-0" v-on:click="lang = 'fr'" :variant="showLangFrVar" :pressed ="showLangFrType"><i class="fab fa-canadian-maple-leaf"></i> FR</b-button>
      </b-button-group>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav variant="info" class="p-1 m-1 g-0">
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'en'" :active="showIntro" v-on:click="closeAllBut('showIntro')" :variant="showIntroButton"><i class="fa-regular fa-square-check"></i> Home</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'en'" :active="showExperience" v-on:click="closeAllBut('showExperience')" :variant="showExperienceButton"><i class="fa-solid fa-train"></i> Experience</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'en'" :active="showParticipation" v-on:click="closeAllBut('showParticipation')" :variant="showParticipationButton"><i class="fa-solid fa-hand-fist"></i> Participation</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'en'" :active="showTestimonials" v-on:click="closeAllBut('showTestimonials')" :variant="showTestimonialsButton"><i class="fa-solid fa-bullhorn"></i> Testimonials</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'en'" :active="showVideo" v-on:click="closeAllBut('showVideo')" :variant="showVideoButton"><i class="fa-solid fa-video"></i> Video</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'en'" :active="showContact" v-on:click="closeAllBut('showContact')" :variant="showContactButton"><i class="fa-regular fa-circle-question"></i> Contact</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'fr'" :active="showIntro" v-on:click="closeAllBut('showIntro')" :variant="showIntroButton"><i class="fa-regular fa-square-check"></i> Accueil</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'fr'" :active="showExperience" v-on:click="closeAllBut('showExperience')" :variant="showExperienceButton"><i class="fa-solid fa-train"></i> Expérience</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'fr'" :active="showParticipation" v-on:click="closeAllBut('showParticipation')" :variant="showParticipationButton"><i class="fa-solid fa-hand-fist"></i> Participation</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'fr'" :active="showTestimonials" v-on:click="closeAllBut('showTestimonials')" :variant="showTestimonialsButton"><i class="fa-solid fa-bullhorn"></i> Témoignages</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'fr'" :active="showVideo" v-on:click="closeAllBut('showVideo')" :variant="showVideoButton"><i class="fa-solid fa-video"></i> Video</b-nav-item>
          <b-nav-item size="sm" class="my-2 my-sm-0" v-if="lang === 'fr'" :active="showContact" v-on:click="closeAllBut('showContact')" :variant="showContactButton"><i class="fa-regular fa-circle-question"></i> Renseignements</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar toggleable="sm"  variant="light" type="light" class="p-1 m-0 g-0" fixed="bottom">
      <img id="unifor" class="m-2" alt="Unifor logo" :src="require('@/assets/unifor.png')" /> 
      <span class="text-danger" v-if="lang === 'en'">Council 4000 Secretary-Treasurer</span>
      <span class="text-danger" v-if="lang === 'fr'">Sécrétaire-Trésorier du Conseil 4000</span>
    </b-navbar>
    <HelloWorld v-if="lang === 'en' && showIntro" :msg=showIntroMessage :lead=showIntroLead ctype="home" lang="en" />
    <HelloWorld v-if="lang === 'fr' && showIntro" :msg=showIntroMessageFr :lead=showIntroLeadFr ctype="home" lang="fr" />
    <HelloWorld v-if="lang === 'en' && showExperience" :msg=showExperienceMessage :lead=showExperienceLead ctype="experience" lang="en" />
    <HelloWorld v-if="lang === 'fr' && showExperience" :msg=showExperienceMessageFr :lead=showExperienceLeadFr  ctype="experience" lang="fr" />
    <HelloWorld v-if="lang === 'en' && showParticipation" :msg=showParticipationMessage :lead=showParticipationLead ctype="participation" lang="en" />
    <HelloWorld v-if="lang === 'fr' && showParticipation" :msg=showParticipationMessageFr :lead=showParticipationLead ctype="participation" lang="fr" />
    <HelloWorld v-if="lang === 'en' && showTestimonials" :msg=showTestimonialsMessage :lead=showTestimonialsLead ctype="testimony" lang="en" />
    <HelloWorld v-if="lang === 'fr' && showTestimonials" :msg=showTestimonialsMessageFr :lead=showTestimonialsLead ctype="testimony" lang="fr" />
    <HelloWorld v-if="lang === 'en' && showContact" :msg=showContactMessage :lead=showContactLead ctype="contact" lang="en" />
    <HelloWorld v-if="lang === 'fr' && showContact" :msg=showContactMessageFr :lead=showContactLead ctype="contact" lang="fr" />
    <HelloWorld v-if="lang === 'en' && showVideo" :msg=showVideoMessage :lead=showVideoLead ctype="video" lang="en" />
    <HelloWorld v-if="lang === 'fr' && showVideo" :msg=showVideoMessageFr :lead=showVideoLead ctype="video" lang="fr" />
  </b-container>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  
    created(){   
    console.log("hello");
    this.lang = this.setLang;
},
  name: "App",
  data: function() {
    return {
      lang: "en",
      showIntro: true,
      showContact: false,
      showVideo: false,
      showExperience: false,
      showParticipation: false,
      showQualifications: false,
      showTestimonials: false,
      showBallotMessage: true,
      showIntroMessage: "A strong voice for fairness, dignity, and respect for all workers",
      showIntroMessageFr: "Une voix pour le respect, la dignité et l’égalité de tous les travailleurs.",
      showIntroLead: "<p>The <strong>Council 4000 Secretary-Treasurer</strong> is one of the most senior leadership roles in the Council. It requires someone with a wealth of experience and a deep understanding of the way the Council works.</p><p>With 14 years of <strong>progressive union experience</strong>, Andrew is uniquely suited for the role of Secretary-Treasurer and we urge you to support him in this election.</p>",
      showIntroLeadFr: "<p>Le <strong>secrétaire-trésorier du Conseil 4000</strong> occupe l'un des postes de direction les plus importants au sein du Conseil. Il faut quelqu'un avec une vaste expérience et une profonde compréhension de la façon dont le Conseil fonctionne.</p><p>Avec 14 ans <strong>d'expérience syndicale progressiste</strong>, Andrew est particulièrement bien placé pour le rôle de secrétaire-trésorier et nous vous exhortons à le soutenir dans cette élection.</p>",
      showContactMessage: "Contact Andrew",
      showContactMessageFr: "Contactez Andrew",
      showContactLead: "",
      showContactLeadFr: "",
      showVideoMessage: "Andrew in his own words",
      showVideoMessageFr: "Andrew dans ses propres mots",
      showVideoLead: "",
      showVideoLeadFr: "",
      showExperienceMessage: "A journey through the transportation industry",
      showExperienceMessageFr: "Un voyage à travers l'industrie du transport",
      showExperienceLead: "Skilled labour relations experience honed through years of Council participation",
      showExperienceLeadFr: "Expérience qualifiée en relations de travail, acquise au cours d'années de participation active au Conseil",
      showParticipationMessage: "A ceaseless champion of workers' rights at every step",
      showParticipationMessageFr: "Un défenseur incessant des droits des travailleurs à chaque étape",
      showParticipationLead: "",
      showParticipationLeadFr: "",
      showTestimonialsMessage: "What other people say about Andrew",
      showTestimonialsMessageFr: "Ce que les autres disent d'Andrew",
      showTestimonialsLead: "",
      showTestimonialsLeadFr: ""
    };
  },

  computed: {
    showIntroButton () {return this.showIntro ? "primary" : "secondary";},
    showContactButton () {return this.showContact ? "primary" : "secondary";},
    showVideoButton () {return this.showVideo ? "primary" : "secondary";},
    showExperienceButton () {return this.showExperience ? "primary" : "secondary";},
    showParticipationButton () {return this.showParticipation ? "primary" : "secondary";},
    showQualificationsButton () {return this.showQualifications ? "primary" : "secondary";},
    showTestimonialsButton () {return this.showTestimonials ? "primary" : "secondary";},
    showLangEnVar () {return this.lang == 'en' ? "light" : "light";},
    showLangEnType () {return this.lang == 'en' ?true:false;},
    showLangFrVar () {return this.lang == 'fr' ? "light" : "light";},
    showLangFrType () {return this.lang == 'fr' ? true:false;},
    setLang() { 
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
      let value = params.lang; // "some_value
      if((value == "fr") || (value == "en")) {
        return value;
      }
    return "en";
  }
},
  // define methods under the `methods` object
  methods: {
 
      closeAllBut: function(value) {
      this.showIntro = false;
      this.showContact = false;
      this.showVideo = false;
      this.showExperience = false;
      this.showParticipation = false;
      this.showQualifications = false;
      this.showTestimonials = false;

      if (value === "showIntro") {
        this.showIntro = true;
      } else if (value === "showContact") {
        this.showContact = true;
      } else if (value === "showVideo") {
        this.showVideo = true;
      } else if (value === "showExperience") {
        this.showExperience = true;
      } else if (value === "showParticipation") {
        this.showParticipation = true;
      } else if (value === "showQualifications") {
        this.showQualifications = true;
      } else if (value === "showTestimonials") {
        this.showTestimonials = true;
      }
    }
  },
  components: {
    HelloWorld
  }
};
</script>
