<template>
  <b-container class="m-0 p-0 g-0" fluid>
    <b-row class="m-0 p-0 g-0">
      <b-col class="m-0 p-0 g-0">  
        <b-img fluid v-if="ctype === 'home'" :src="require('@/assets/header3.png')" class="m-0 p-0 g-0"
        alt="Andrew Stephen participating in a Labour Day march with Unifor members"></b-img>  
        <b-img fluid-grow v-if="ctype === 'experience'" :src="require('@/assets/election2016.jpg')"  class="m-0 p-0 g-0"
        alt="Group photo of Unifor council in 2016 with Andrew Stephen toward the left"
        ></b-img>        
        <b-img fluid-grow v-if="ctype === 'participation'" :src="require('@/assets/negotiations2019.jpg')"  class="m-0 p-0 g-0"
        alt="Responsive image"></b-img>
      </b-col>
    </b-row>

    <b-row class="m-0 p-0 g-0">
      <b-col class="m-0 p-0 g-0">  
      <b-jumbotron fluid-grow class="p-5 g-0" bg-variant="primary" text-variant="white" border-variant="dark" :header-html="msg" :lead-html="lead">
        <div v-if="ctype === 'contact'">
          <b-button class="m-2" variant="info" href="tel:647-669-8723">
            <i class="fas fa-mobile-alt"></i>&nbsp;&nbsp;(647) 669-8723
          </b-button> 
          <b-button class="m-2" variant="info" href="mailto:a.stephen@unifor4000.com">
            <i class="far fa-envelope"></i>&nbsp;&nbsp; a.stephen@unifor4000.com
          </b-button>
        </div>

        

      </b-jumbotron>
      </b-col>
    </b-row>

    <b-row v-if="ctype === 'election' & lang === 'en'" class="m-0 p-0 g-0">
      <b-card class="col-12 p-5" bg-variant="warning">
        <h3>Didn't get a ballot for the Council 4000 election?</h3>
        <p><strong>Email <a class="text-black" href="mailto:x@y.z?subject=Ballot request&body=I did not receive a ballot for the Council 4000 election">x@y.z</a> to register for a ballot.</strong></p>
    </b-card>
    </b-row>

    <b-row v-if="ctype === 'contact' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-lg-6 col-md-6 p-5">
        <h3 class="text-primary">Download posters</h3>
        <b-img :src="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-bilingual.jpg')" alt="Dual language poster" class="w-100" />
        <h4><a :href="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-bilingual.jpg')">download</a></h4>
      </b-col>
      <b-col class="col-12 col-lg-3 col-md-3 p-5">
        <h3 class="text-primary">English poster</h3>
        <b-img :src="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-english.jpg')" alt="English language poster" class="w-100" />
        <h4><a :href="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-english.jpg')">download</a></h4>
      </b-col>
      <b-col class="col-12 col-lg-3 col-md-3 p-5">
        <h3 class="text-primary">French poster</h3>
        <b-img :src="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-french.jpg')" alt="French language poster" class="w-100" />
        <h4><a :href="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-french.jpg')">download</a></h4>
      </b-col>
    </b-row>

    <b-row v-if="ctype === 'video' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-lg-6 col-md-6 p-5">
        <iframe height="421" width="315" src="https://www.youtube.com/embed/MDJHx9ZEESg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </b-col>
    </b-row>
    <b-row v-if="ctype === 'video' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 p-5">
        <iframe height="421" width="315" src="https://www.youtube.com/embed/2yCJdBDb59Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </b-col>
    </b-row>
    <!-- ENGLISH -->
    <b-row v-if="ctype === 'home' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-lg-6 col-md-6 p-5">
        <h3 class="text-primary">Role of Secretary-Treasurer</h3>
        <p>The position isn’t only about signing cheques and verifying expenses: the Secretary-Treasurer sometimes presents cases at CROA and mediated arbitration, is called to stand in for the President in their absence, is responsible for providing guidance to Regional Representatives, Local Presidents, Chairpersons and workplace representatives, sits on the Council’s executive committee, pension and policy health and safety committees, and has a hand in setting the bargaining priorities for all national collective agreements. </p>
        <p>The position therefore requires a wealth of broad-based labour relations knowledge and experience, and requires someone with tact, judgement, diplomacy, focus, and a clear understanding of the legal and political landscape.</p>
      </b-col>

      <b-col class="col-12 col-md-6 g-0">
          <b-img :src="require('@/assets/conference2013.png')" class="w-100"
        alt="Photo from 2013 Unifor convention with Andrew at head table as part of the Resolutions and Bylaws committee" /> 
      </b-col>
    </b-row>

    <b-row v-if="ctype === 'home' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-lg-6 col-md-6 p-5">
        <h3 class="text-primary">Future of Secretary-Treasurer</h3>
        <p>In addition to what the Secretary-Treasurer currently does, we should also be looking to the future. The role can be much more than what it already is, taking responsibility for important things like communications, organizational planning and development, training, on-boarding, fundraising, and other areas of opportunity.</p>
        <p>Andrew has the experience, education, and vision to reinvigorate this role and realize its potential.</p>
      </b-col>
      <b-col class="col-12 col-lg-6 col-md-6 p-5">  
        <b-img right thumbnail
            :src="require('@/assets/andrew.png')" 
            alt="Photo of Andrew Stephen"
            ></b-img>      
      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'experience' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-8 p-5">
        <h3 class="text-primary">Experience</h3>
        <p>Andrew started his career with VIA Rail in 2000, and was elected as a <strong>Grievance Officer</strong> in 2008. He served as <strong>Local Chairperson</strong> and <strong>Local Vice-President</strong> between 2010 and 2015, after which he was elected as a <strong>Regional Representative</strong>, a position he has served in for more than 7 years.</p>
        <p>Andrew is <strong>fluently bilingual</strong> and has represented members in investigations, committee meetings, and at conventions in both official languages.</p>
        <h4 class="text-secondary">Council 4000</h4>
        <p>Andrew has served as the Regional Representative member of Council 4000’s <strong>Executive Committee</strong> since 2016.</p>
        <h4 class="text-secondary">Committees</h4>
        <p>In addition, Andrew has twice sat on the <strong>Resolutions and Bylaws committee</strong> at the Council 4000 Convention, including in 2013 when the new Council bylaws were adopted.</p>
        <p>Andrew has broad experience at the <strong>local</strong> and <strong>executive</strong> levels which gives him a unique insight into the administration of the Council.</p>  
      </b-col>
      <b-col class="col-12 col-md-4"> 
        <b-img :src="require('@/assets/saputo2022.jpg')"  class="w-100"
        alt="Group photo of Unifor council in 2016 with Andrew Stephen toward the left"
        ></b-img>        
      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'experience' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-4"> 
        <b-img :src="require('@/assets/conference2018.jpg')" class="w-100" alt="Group photo of Unifor bargaining group in 2019 with Andrew Stephen toward the right" />
      </b-col>
      <b-col class="col-12 col-md-5 p-5">
        <h3 class="text-primary">Education and training</h3>
        <h4 class="text-secondary">Queen’s University</h4>
        <ul>
          <li>BA, Political Science</li>
        </ul>
        <h4 class="text-secondary">Sheridan College</h4>
        <ul>
          <li>Human Resources</li>
        </ul>
        <h4 class="text-secondary">Unifor Education Centre</h4>
        <ul>
          <li>Collective Bargaining</li>
          <li>Grievance Handling</li>
          <li>WSIB Appeals</li>
          <li>Economics for Trade Unionists</li>
        </ul>
      </b-col>
      <b-col class="col-12 col-md-3"> 
        <b-img :src="require('@/assets/podium.jpg')" class="w-100" alt="Group photo of Unifor bargaining group in 2019 with Andrew Stephen toward the right" />
      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'participation' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-6 p-5 g-0">
        <h3 class="text-primary">Bargaining & Arbitration</h3>
        <p>Over the course of his career, Andrew has bargained collective agreements in federal and provincial jurisdictions, including chairing bargaining, written thousands of grievances and presented hundreds at mediated-arbitration.</p>
        <p>Andrew has been the lead representative in several cases heard by quasi-judicial bodies such as the Canadian Human Rights Tribunal and the Workplace Safety and Insurance Tribunal, and is the only candidate to present a case at the Canadian Railway Office of Arbitration.</p>
      </b-col>
      <b-col class="col-12 col-md-3 g-0">   
        <b-img :src="require('@/assets/labourday2015-2.jpg')" class="w-100" alt="Andrew Stephen marches in the 2015 Labour day parade" />
      </b-col>
      <b-col class="col-12 col-md-3 g-0">   
        <b-img :src="require('@/assets/labourday2015.jpg')" class="w-100" alt="Andrew Stephen poses with Olivia Chow at the 2015 Labour day parade" />
      </b-col>
    </b-row>

    <b-row v-if="ctype === 'participation' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-6 p-5 g-0">
      <h3 class="text-primary">Community Involvement</h3>
      <p>Andrew has volunteered hundreds of hours in municipal, provincial, and federal election campaigns since 2000, working the phones and knocking on doors to elect progressive candidates. He was a key organizer in White Ribbon Campaign events, seeking to raise funds and awareness on violence against women and girls. Additionally, Andrew sat on the steering committee of the Green Economy Network, a group of labour and environmental activists working to bring about a just transition. </p>
      </b-col>
      <b-col class="col-12 col-md-6 g-0">
         
      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'testimony' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Passion and dedication</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> I got my beginnings in the labour movement in 2015. Like many people who are starting out, I didn't even know where to begin. To be successful in this movement it takes a good mentor. Someone who will know how to point you in the right direction and reassure you that the work you have done is enough. As a mentor I had quite a good one and I consider myself very lucky.</p>
        <p>Andrew Stephen is the one who convinced me to get involved in my early days. It was also him who helped me understand that I had what it takes to be succesful in the position of Local President. His passion and dedication to our members is unmatched. Andrew knows the time to celebrate is when the job is done. He showed me that we must be at our absolute best when we negotiate with management. It is for this and other reasons that I am happy to endorse Andrew Stephen as Secretary Treasurer of Unifor Council 4000.</p>
        <p>Everyone should have a mentor as qualified as Andrew and if we did, we would be well served I have no doubt. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Gordon Cox</h4>
        <h5>President, Local 4004</h5>
      </b-col>

      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Vast experience</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> I am writing this letter to extend my support and endorsement to Brother Andrew Stephen in his pursuit to be elected to the office of Secretary Treasurer.</p>
        <p>Andrew has served as a Union Representative servicing VIA Rail for over 14 years. He currently holds the office of Unifor Regional Representative within Local 4003 (VIA Agreement 1 & 2, and Saputo) as well as Local 4004 (VIA Agreement 2) for the past three consecutive terms.</p>
        <p>Andrew has strong working knowledge and organizational skills, which in combination with his vast experience fighting for the rights of his members, makes him the quintessential choice.</p>
        <p>I can confidently say he will perform this role with the highest level of integrity which is expected in the Office of Secretary Treasurer. He is highly competent and will dedicate himself to this office as well as be a source of support and guidance to the Council and the membership in which we serve. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Kristi Boisvert</h4>
        <h5>Regional Representative, Unifor Council 4000<br />CN 5.1, 5.1 Supplemental, TTR</h5>
      </b-col>

        <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>An excellent mentor</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> I have known Andrew for almost 20 years and have witnessed his dedication to the membership and to advancing social justice in Canada first hand. I am consistently impressed by his work ethic, his strength, and his diligence. He places the members he represents at the centre of everything he does, and takes the time to explain complex issues to those he is helping.</p>
        <p>Andrew is an excellent mentor to his team and has repeatedly demonstrated his willingness to share his knowledge with others, for the betterment of all members. He doesn’t hesitate to make himself available when a member of the team needs his help and expertise. I have every confidence that he will make an excellent Secretary-Treasurer and am excited to support him in this election. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Greg Sheik</h4>
        <h5>National Health and Safety Representative, Unifor Council 4000</h5>
      </b-col>
</b-row>

<b-row v-if="ctype === 'testimony' && lang === 'en'" class="m-0 p-0 g-0">
  <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Excellent leadership</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i>I have met Andrew Stephen many times while doing Union work. Each time I have been impressed with the way he conducts himself in a very professional manner. He always made time to help me with issues or topics that I brought to him for advice. He is a strong asset for Council 4000 and has always shown strong leadership skills. </p><p>Since I was elected as a Regional Representative for the Mountain Region I have often reached out to Brother Stephen for advice. He has always made time to take my calls and help guide me or share his own work with me so I would have a template to help our Sisters and Brothers in the Mountain Region. I am confident if elected Andrew Stephen would provide excellent leadership for all our Sisters and Brothers represented by Council 4000. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">David Judge</h4>
        <h5>Regional Representative, Unifor Council 4000<br />CN 5.1 & 5.5, CN SAR, VIA Agreements Nos. 1 & 2</h5>
      </b-col>    
      
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>An incredible resource</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> Andrew Stephen is a tireless supporter of working people and a champion for social justice. He moved up the ranks from shop floor to experienced negotiator at the bargaining table. He's an incredible resource. Never too busy, always available to help.</p>
        <p>He's fiscally responsible, thorough, diligent and sincere in his efforts. I recommend you vote for Andrew! <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Christopher Graper</h4>
        <h5>Local Chairperson, Local 4003<br />VIA Agreement No. 2</h5>
      </b-col>    
      
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>A great asset</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> Andrew Stephen is a dedicated brother to all the members he represents and also to Unifor. Available at all times, he never hesitates to defend a member and does not tolerate injustice. He believes in the trade union movement and has been involved in it for nearly 15 years. Andrew has succeeded in marking his passage as Regional Representative, not only at the regional level but also at the national level. Andrew will be a great asset to the position of Secretary-Treasurer and will no doubt leave his mark there as well. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Cedrick Campbell</h4>
        <h5>Local Chairperson, Local 4004<br />VIA Agreement No. 2</h5>
      </b-col>

    </b-row>
    <b-row v-if="ctype === 'testimony' && lang === 'en'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Demands accountability</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> Throughout my career, as I struggle with the complexities of my roles, Andrew is always there to guide and mentor me, or just be a sounding board. When I have difficulties writing, he dots my "I"s and crosses my "T"s. I can always bounce ideas his way and trust him to give me honest and constructive feedback.</p>
        <p>An excellent listener and adept at communication, Andrew is an eloquent speaker and an expert debater. As a professional trouble-maker, he knows how and when to bring the fight. He speaks truth to power and demands accountability.</p>
        <p>In a role where integrity is a vital currency, Andrew values honesty above politics. He will not misrepresent himself and is never too proud to admit he's made a mistake.</p>
        <p>Andrew is the embodiment of a Union leader: a mentor; a friend; a brother. I proudly endorse him for the office of Secretary-Treasurer, Unifor, National Council 4000. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Jeremie Dupuis</h4>
        <h5>Local Chairperson, Local 4003<br />VIA Agreement No. 1</h5>
      </b-col>

      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Knowledgeable, hardworking and reliable</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> I've worked with Andrew in Local 4003 for around a decade and find him to be very knowledgeable, hardworking and reliable. I have full confidence that he will fulfill this new role with the same thoughtfulness and enthusiasm that I've seen in him so far. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Kris Willett</h4>
        <h5>Chairperson of Trustees, Local 4003<br>CN 5.1 Supplemental<br /></h5>
      </b-col>

      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>A valuable resource</em></h3>
        <p><i class="fa-solid fa-quote-left text-primary"></i> I have been with VIA for 35 years and I have worked as a Grievance Officer with Andrew and Unifor for the past 8 years.</p>
        <p>Andrew has always been a valuable resource for me when needing help with investigations and grievances. Andrew has a vast amount of experience when dealing with union matters and our contracts. Andrew has always been available to take my calls, texts and emails on behalf of our members.</p>
        <p>Andrew is a person who has integrity, honesty and has always been fiscally responsible. Andrew has my support for Secretary-Treasurer. <i class="fa-solid fa-quote-right text-primary"></i></p>
        <h4 class="text-danger">Sailas Joshi</h4>
        <h5>Grievance Officer, Local 4003<br>VIA Agreement No. 1<br /></h5>
      </b-col>
    </b-row>

    <!-- FRENCH -->
    <b-row v-if="ctype === 'contact' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-lg-6 col-md-6 p-5">
        <h3 class="text-primary">Télécharger les affiches</h3>
        <b-img :src="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-bilingual.jpg')" alt="Dual language poster" class="w-100" />
        <h4><a :href="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-bilingual.jpg')">télécharger</a></h4>
      </b-col>
      <b-col class="col-12 col-lg-3 col-md-3 p-5">
        <h3 class="text-primary">Affiche française</h3>
        <b-img :src="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-french.jpg')" alt="French language poster" class="w-100" />
        <h4><a :href="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-french.jpg')">télécharger</a></h4>
      </b-col>
      <b-col class="col-12 col-lg-3 col-md-3 p-5">
        <h3 class="text-primary">Affiche anglaise</h3>
        <b-img :src="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-english.jpg')" alt="English language poster" class="w-100" />
        <h4><a :href="require('@/assets/Andrew-Stephen-Secretary-Treasurer-2022-english.jpg')">télécharger</a></h4>
      </b-col>
    </b-row>


    <b-row v-if="ctype === 'home' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-lg-6 col-md-6 p-5">
        <h3 class="text-primary">Rôle du Secrétaire-trésorier</h3>
        <p>Le poste ne consiste pas seulement à signer des chèques et à vérifier les dépenses : le secrétaire-trésorier présente parfois des cas au BAMCFC et à la médiation arbitrage, est appelé à remplacer le président en son absence, est responsable de fournir des conseils aux représentants régionaux, aux présidents locaux, présidents du comités des griefs, et représentants en milieu de travail, il / elle siège au comité exécutif du Conseil, aux comités des pensions et des politiques de santé et de sécurité, et participe à l'établissement des priorités de négociation pour toutes les conventions collectives nationales.</p>
        <p>Le poste exige donc une richesse de connaissances et d'expérience générales en relations de travail, et nécessite une personne avec du tact, du jugement, de la diplomatie, de la concentration et une compréhension claire du paysage juridique et politique.</p>
      </b-col>

      <b-col class="col-12 col-md-6 g-0">
          <b-img :src="require('@/assets/conference2013.png')" class="w-100"
        alt="Photo from 2013 Unifor convention with Andrew at head table as part of the Resolutions and Bylaws committee" /> 
      </b-col>
    </b-row>

    <b-row v-if="ctype === 'home' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-lg-6 col-md-6 p-5">
        <h3 class="text-primary">l’avenir de secrétaire-trésorier</h3>
        <p>En plus de ce que le secrétaire-trésorier fait présentement, nous devons aussi nous tourner vers l'avenir. Le rôle peut être bien plus que ce qu'il est maintenant, en prenant la responsabilité de choses importantes comme les communications, la planification et le développement organisationnels, la formation, l'intégration, la collecte de fonds et d'autres domaines d'opportunité.</p>
        <p>Andrew possède l'expérience, l'éducation et la vision nécessaires pour redynamiser ce rôle et réaliser son plein potentiel.</p>
      </b-col>
      <b-col class="col-12 col-lg-6 col-md-6 p-5">  
        <b-img right thumbnail
            :src="require('@/assets/andrew.png')" 
            alt="Photo of Andrew Stephen"
            ></b-img>      
      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'experience' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-8 p-5">
        <h3 class="text-primary">Expérience </h3>
        <p>Andrew a commencé sa carrière chez VIA Rail en 2000 et a été élu comme <strong>délégué syndical</strong> en 2008. Il a été <strong>vice-président local</strong> entre 2010 et 2015, après quoi il a été élu <strong>représentant régional</strong>, poste qu'il occupe depuis plus de 7 ans.</p>

        <p>Andrew est <strong>parfaitement bilingue</strong> et a représenté des membres lors d'enquêtes, de réunions de comités et de congrès dans les deux langues officielles.
        </p>

        <h4 class="text-secondary">Conseil 4000</h4>
        <p>Andrew est membre du <strong>comité exécutif</strong> du Conseil 4000 depuis 2016.</p>

        <h4 class="text-secondary">Comités</h4>
        <p>De plus, Andrew a siégé à deux reprises au <strong>comité des résolutions et des règlements</strong> lors du congrès du Conseil 4000, y compris en 2013 lorsque les nouveaux règlements du Conseil ont été adoptés.</p>
        <p>Andrew possède une vaste expérience aux niveaux <strong>local</strong> et <strong>exécutif</strong>, ce qui lui donne un aperçu unique de l'administration du Conseil.</p>

      </b-col>
      <b-col class="col-12 col-md-4"> 
        <b-img :src="require('@/assets/saputo2022.jpg')"  class="w-100"
        alt="Group photo of Unifor Council 4000 Convention in 2016 with Andrew Stephen toward the left"
        ></b-img>        
      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'experience' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-4"> 
        <b-img :src="require('@/assets/conference2018.jpg')" class="w-100" alt="Group photo of Unifor bargaining group in 2019 with Andrew Stephen toward the right" />
      </b-col>
      <b-col class="col-12 col-md-5 p-5">
        <h3 class="text-primary">Éducation et formation</h3>
        <h4 class="text-secondary">Queen’s University</h4>
        <ul>
          <li>Baccalaureat, Sciences politiques</li>
        </ul>
        <h4 class="text-secondary">Sheridan College</h4>
        <ul>
          <li>Ressources humaines</li>
        </ul>
        <h4 class="text-secondary">Centre familial d’éducation</h4>
        <ul>
          <li>La négociation collective</li>
          <li>Traitement des griefs</li>
          <li>Appels de la CSPAAT</li>
          <li>L'économie pour les syndicalistes</li>
        </ul>
      </b-col>
      <b-col class="col-12 col-md-3"> 
        <b-img :src="require('@/assets/podium.jpg')" class="w-100" alt="Group photo of Unifor bargaining group in 2019 with Andrew Stephen toward the right" />
      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'participation' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-6 p-5 g-0">
        <h3 class="text-primary">Négociation et arbitrage</h3>
        <p>Au cours de sa carrière, Andrew a négocié des conventions collectives dans les juridictions fédérales et provinciales, et a également  présidé des negotiations. Il a rédigé des milliers de griefs et en a présenté des centaines lors de médiation-arbitrage.</p>
        <p>Andrew a été le principal représentant dans plusieurs affaires entendues par des organismes quasi judiciaires tels que le Tribunal canadien des droits de la personne et le Tribunal de la sécurité professionnelle et de l'assurance contre les accidents du travail, et est le seul candidat à présenter un dossier au Bureau d'arbitrage des chemins de fer du Canada.</p>
      </b-col>
      <b-col class="col-12 col-md-3 g-0">   
        <b-img :src="require('@/assets/labourday2015-2.jpg')" class="w-100" alt="Andrew Stephen marches in the 2015 Labour day parade" />
      </b-col>
      <b-col class="col-12 col-md-3 g-0">   
        <b-img :src="require('@/assets/labourday2015.jpg')" class="w-100" alt="Andrew Stephen poses with Olivia Chow at the 2015 Labour day parade" />
      </b-col>
    </b-row>

    <b-row v-if="ctype === 'participation' && lang === 'fr'" class="m-0 p-0 g-0">
      <b-col class="col-12 col-md-6 p-5 g-0">
      <h3 class="text-primary">Implication dans la communauté</h3>
      <p>Andrew a consacré des centaines d'heures à des campagnes électorales municipales, provinciales et fédérales depuis 2000, travaillant au téléphone et frappant aux portes pour élire des candidats progressistes. Il a été un organisateur clé des événements de la campagne du ruban blanc, cherchant à collecter des fonds et à sensibiliser à la violence contre les femmes et les filles. De plus, Andrew a siégé au comité directeur du Réseau économique vert, un groupe de militants syndicaux et environnementaux travaillant pour une transition juste.</p>
      </b-col>
      <b-col class="col-12 col-md-6 g-0">

      </b-col>
    </b-row>
    
    <b-row v-if="ctype === 'testimony' && lang === 'fr'" class="m-0 p-0 g-0">

      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Passion et dévouement</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> Jai fait mes débuts dans le mouvement syndicale en 2015. Comme plusieurs personnes qui s’implique je ne savais même pas par où commencer. Pour avoir du succès dans ce mouvement ça prend un bon mentor. Quelqu’un qui va savoir te diriger dans la bonne direction et de te rassurer que le travail que tu en a fais assez. Un mentor, j’en ai eu tout un et je me trouve très chanceux.</p>
        <p>Andrew Stephen est la personne qui m’a convaincu de m’impliquer dans mes débuts. C’est aussi lui qui m’a fait comprendre que j’avais ce que ça me prenait pour occuper le poste de président de la section locale. Sa passion et son dévouement pour nos membres est inégalé. Andrew sait que le temps de célébrer est une fois le travail accompli. Il m’a démontré qu’on doit être plus propre que propre quand on négocie avec nos gestionnaires. C’est pour cela et d’autres raison que je suis content d'endosser Andrew Stephen au poste de Secrétaire Trésorier du Conseil 4000 d’Unifor.</p>
        <p>Tout le monde devrait avoir un mentor aussi qualifié qu’Andrew et nous serions bien servi j’en ai aucun doute. <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Gordon Cox</h4>
        <h5>Président, Section locale 4004</h5>
      </b-col>

      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Vaste expérience</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> J'écris cette lettre pour exprimer mon soutien et mon approbation au confrère Andrew Stephen dans sa quête d'être élu au poste de secrétaire-trésorier.</p>
        <p>Andrew a été représentant syndical au service de VIA Rail pendant plus de 14 ans. Il occupe actuellement le poste de Représentant Régional d'Unifor au sein de la section locale 4003 (VIA Convention 1 & 2, et Saputo) ainsi que la section locale 4004 (VIA Convention 2) pour les trois derniers mandats consécutifs.</p>
        <p>Andrew possède de solides connaissances pratiques et des compétences organisationnelles qui, combinées à sa vaste expérience dans la lutte pour les droits de ses membres, font de lui le choix par excellence.</p>
        <p>Je peux dire avec confiance qu'il remplira ce rôle avec le plus haut niveau d'intégrité auquel on s'attend dans le Bureau du secrétaire-trésorier. Il est hautement compétent et se consacrera à ce poste tout en étant une source de soutien et d'orientation pour le Conseil et les membres au sein desquels nous servons. <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Kristi Boisvert</h4>
        <h5>Représentant Régional, Conseil 4000 d'Unifor<br />CN 5.1, CN 5.1 Complémentaire, TTR</h5>
      </b-col>

      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Un excellent mentor</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> Je connais Andrew depuis près de 20 ans et j'ai été témoin de son dévouement envers les membres et de l'avancement de la justice sociale au Canada. Je suis constamment impressionné par son éthique de travail, sa force et sa diligence. Il place les membres qu'il représente au centre de tout ce qu'il fait et prend le temps d'expliquer les enjeux complexes à ceux et celles qu'il aide.</p>
        <p>Andrew est un excellent mentor pour son équipe et a démontré à maintes reprises sa volonté de partager ses connaissances avec les autres, pour le bien de tous les membres. Il n'hésite pas à se rendre disponible lorsqu'un membre de l'équipe a besoin de son aide et de son expertise. Je suis convaincu qu'il fera un excellent secrétaire-trésorier et je suis ravi de l'appuyer dans cette élection.
      <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Greg Sheik</h4>
        <h5>Représentant national en santé et sécurité, Conseil 4000 d'Unifor</h5>
      </b-col>
      
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Excellent leadership</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> J'ai rencontré Andrew Stephen à plusieurs reprises dans le cadre de mon travail syndical.  Chaque fois, j'ai été impressionné par la façon dont il se comporte de manière très professionnelle.  Il a toujours pris le temps de m'aider avec des problèmes ou des sujets que je lui apportais pour obtenir des conseils.  Il est un atout important pour le Conseil 4000 et a toujours fait preuve de solides compétences en leadership.</p><p>Depuis que j'ai été élu représentant régional pour la région des montagnes, j'ai souvent contacté le frère Stephen pour obtenir des conseils.  Il a toujours pris le temps de prendre mes appels et de m'aider à me guider ou à partager son propre travail avec moi afin que j'aie un modèle pour aider nos sœurs et frères de la région des montagnes.  Je suis convaincu que si Andrew Stephen était élu, il fournirait un excellent leadership à tous nos consœurs et confrères représentés par le Conseil 4000. <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">David Judge</h4>
        <h5>Représentant Régional, Conseil 4000 d'Unifor<br />VCN 5.1 & 5.5, CN SAR, VIA Conventions N°s. 1 & 2</h5>
      </b-col>
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Une ressource incroyable</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> Andrew Stephen est un partisan infatigable des travailleurs et un champion de la justice sociale. Il a gravi les échelons d'atelier à négociateur expérimenté à la table de négociation. C'est une ressource incroyable. Jamais trop occupé, toujours disponible pour vous aider.</p>
        <p>Il est financièrement responsable, minutieux, diligent et sincère dans ses efforts. Je vous recommande de voter pour Andrew ! <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Christopher Graper</h4>
        <h5>Vice-président, Section locale 4003<br />VIA Convention N° 2</h5>
      </b-col>
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Un excellent atout</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> Andrew Stephen est un confrère dévoué envers tous les membres qu’il représente, mais également envers Unifor. Disponible en tout temps, il n’hésite jamais à défendre un membre et ne tolère aucunement l’injustice. Il croit au mouvement syndical et s’y implique depuis presque 15 ans. Andrew a réussi à marquer son passage comme Représentant Régional, non seulement au niveau régional mais, tout autant au niveau national. Andrew sera un excellent atout au poste de secrétaire-trésorier et sans aucun doute y laissera sa trace également. <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Cédrick Campbell</h4>
        <h5>Vice-président, Section locale 4004<br />VIA Convention N° 2</h5>
      </b-col>

      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Exige la responsabilité</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> Tout au long de ma carrière, alors que je lutte avec la complexité de mes rôles, Andrew est toujours là pour me guider et me conseiller, ou simplement être une caisse de résonance. Quand j'ai des difficultés à écrire, il met les points sur mes i et les barres sur mes t. Je peux toujours lui proposer des idées et lui faire confiance pour me donner des commentaires honnêtes et constructifs.</p>
        <p>Excellent auditeur et adepte de la communication, Andrew est un orateur éloquent et un débatteur expert. En tant que fauteur de troubles professionnel, il sait comment et quand se battre. Il dit la vérité au pouvoir et exige la responsabilité.</p>
        <p>Dans un rôle où l'intégrité est une devise vitale, Andrew valorise l'honnêteté au-dessus de la politique. Il ne se déformera pas et n'est jamais trop fier pour admettre qu'il s'est trompé.</p>
        <p>Andrew est l'incarnation d'un leader syndical : un mentor ; un ami ; un confrère. Je l'appuie fièrement pour le poste de secrétaire-trésorier du Conseil national 4000 d'Unifor. <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Jeremie Dupuis</h4>
        <h5>Vice-président, Section locale 4003<br />VIA Convention N° 1</h5>
      </b-col>

    </b-row>
    
    <b-row v-if="ctype === 'testimony' && lang === 'fr'" class="m-0 p-0 g-0">
      
      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Compétent, travaillant et fiable</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> J'ai travaillé avec Andrew dans la section locale 4003 pendant environ une décennie et je le trouve très compétent, travaillant et fiable. J'ai pleinement confiance qu'il remplira ce nouveau rôle avec la même attention et le même enthousiasme que j'ai vus en lui jusqu'à présent. <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Kris Willett</h4>
        <h5>Président des syndics, Section locale 4003<br />CN 5.1 Complémentaire</h5>
      </b-col>


      <b-col class="col-12 col-md-4 p-5 g-0">
        <h3 class="text-primary"><em>Une ressource précieuse</em></h3>
        <p><i class="fa-solid fa-angles-left text-primary"></i> Je suis à VIA depuis 35 ans et j'ai travaillé comme délégué syndical avec Andrew et Unifor au cours des 8 dernières années.</p>
        <p>Andrew a toujours été une ressource précieuse pour moi lorsque j'avais besoin d'aide pour des enquêtes et des griefs. Andrew possède une vaste expérience dans le traitement des questions syndicales et de nos contrats. Andrew a toujours été disponible pour prendre mes appels, textos et courriels au nom de nos membres.</p>
        <p>Andrew est une personne intègre, honnête et qui a toujours été financièrement responsable. Andrew a mon soutien en tant que secrétaire-trésorier. <i class="fa-solid fa-angles-right text-primary"></i></p>
        <h4 class="text-danger">Sailas Joshi</h4>
        <h5>Délégué syndical, Section locale 4003<br>VIA Convention N° 1<br /></h5>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
    data() {
      return {
        mainProps: {width: "200px", height: "200px", class: 'm1' },
        dismissSecs: 10,
        dismissCountDown: 0,
        showDismissibleAlert: true
      }
    },
  name: "HelloWorld",
  props: {
    msg: String,
    lead: String,
    ctype: String,
    lang: String
  }, methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    }
};
</script>

